import * as changedpi from 'changedpi';

//镜像翻转图片方法
export function flipImage(base64Image) {
    return new Promise((resolve, reject) => {  
        function createImage(base64) {  
            return new Promise((resolve, reject) => {  
                const img = new Image();  
                img.onload = () => {  
                    console.log(`Loaded image with size: ${img.width}x${img.height}`);  
                    resolve(img);  
                };  
                img.onerror = (error) => {  
                    console.error('Error loading image:', error);  
                    reject(error);  
                };  
                img.src = base64;  
            });  
        }  
  
        Promise.all([createImage(base64Image)])  
            .then(([img]) => {  
                const canvas = document.createElement('canvas');  
                const ctx = canvas.getContext('2d');  
  
                // 确保图片尺寸有效  
                if (img.width === 0 || img.height === 0) {  
                    throw new Error('First image has invalid dimensions');  
                } 

                canvas.width =img.width; 
                
                canvas.height = img.height;
                // 垂直翻转图片
                ctx.translate(canvas.width, 0);
                ctx.scale(-1, 1);
                ctx.drawImage(img, 0, 0);
                
                const mirrorBase64 = canvas.toDataURL('image/png');  
                resolve(mirrorBase64);  
            })  
            .catch(error => {  
                console.error('Error merging images:', error);  
                reject(error);  
            });  
    });  
}    

//将背景图片与底图合成方法    
export function mergeBase64Images(base64Image1, base64Image2) {  
    return new Promise((resolve, reject) => {  
        function createImage(base64) {  
            return new Promise((resolve, reject) => {  
                const img = new Image();  
                img.onload = () => {  
                    console.log(`Loaded image with size: ${img.width}x${img.height}`);  
                    resolve(img);  
                };  
                img.onerror = (error) => {  
                    console.error('Error loading image:', error);  
                    reject(error);  
                };  
                img.src = base64;  
            });  
        }  
  
        Promise.all([createImage(base64Image1), createImage(base64Image2)])  
            .then(([img1, img2]) => {  
                const canvas = document.createElement('canvas');  
                const ctx = canvas.getContext('2d');  
  
                // 确保图片尺寸有效  
                if (img1.width === 0 || img1.height === 0) {  
                    throw new Error('First image has invalid dimensions');  
                }  
                if (img2.width === 0 || img2.height === 0) {  
                    throw new Error('Second image has invalid dimensions');  
                }  

                canvas.width = Math.max(img1.width, img2.width); 
                
                canvas.height = Math.max(img1.height, img2.height);
                 
  
                ctx.drawImage(img1, 0, 0);
                //定位背景图在底图上合成的位置(绘制到上下文的元素，image 的左上角在目标画布上 X 轴坐标，image 的左上角在目标画布上 Y 轴坐标)
                // ctx.drawImage(img2, 994-(img2.width/2), 1537-(img2.height/2)); //原方法
                ctx.drawImage(img2, (994/84*localStorage.getItem("leftMarginHot"))-(img2.width/2), (1537/130*localStorage.getItem("topMarginHot"))-(img2.height/2)); //与后台数值，取值
                //处理图片dpi并导出toDataURL设置('image/png')时生成的图片位深度为36会导致打印‘状态错误’，设置('image/jpeg')时生成的图片位深度为24则正常。
                const mergedBase64 = changedpi.changeDpiDataUrl(canvas.toDataURL('image/jpeg'), 300);  
                resolve(mergedBase64);  
            })  
            .catch(error => {  
                console.error('Error merging images:', error);  
                reject(error);  
            });  
    });  
} 

//imgurl转base64
export function getBase64(imgurl) {
    return new Promise((resolve, reject) => {
        function createImg(pictrue) {
            return new Promise((resolve, reject) => {
                const Img = new Image();
                Img.src = pictrue + '?v=' + Math.random();
                Img.setAttribute('crossOrigin', 'Anonymous');
                Img.onload = () => {
                    console.log(`Loaded image with size: ${Img.width}x${Img.height}`);
                    resolve(Img);
                };
                Img.onerror = (error) => {
                    console.error('Error loading image:', error);
                    reject(error);
                };
            });
        }

        Promise.all([createImg(imgurl)])
            .then(([imgData]) => {
                let canvas = document.createElement('canvas'),
                    width = imgData.width,
                    height = imgData.height,
                    dataURL;
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(imgData, 0, 0, width, height);
                dataURL = canvas.toDataURL('image/jpeg', 1); //可选取多种模式
                resolve(dataURL);
            })
            .catch(error => {
                console.error('Error merging images:', error);
                reject(error);
            });
    })
}